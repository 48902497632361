import React from "react"

import { PhotoPlaceholder } from "react-placeholder-image"
import { GiStoneThrone } from "react-icons/gi"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

const TlePage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="grid-2-2 has-mb-5">
      <div
        data-sal="slide-up"
        data-sal-delay="500"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh", justifyContent: "space-between" }}
        className="has-padding-4  has-bg-blue align-content-vcenter has-txt-white"
      >
        <div>
          <h1 className="has-txt-bold" style={{ marginBottom: "1rem" }}>
            The Light Ear
          </h1>
          <h3>
            <span className="has-mb-5 tags has-txt-bold">Logo Design</span>{" "}
            <span className="has-mb-5 tags has-txt-bold">
              Web Design & development
            </span>{" "}
          </h3>
        </div>
        <p>
          Bringing the spirit that drives this band to the forefront, helping
          the band rediscover its identity and get the grove out in the branding
          process. <br />
          <br />
          We helped them realize the true potential of having a kickass design
          that solves problems. We designed the process around the ideology of
          free will and sheer magnificence that their music is and built an
          identity system that left a mark wherever it went.
        </p>
      </div>

      <div
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/thelightear/1.jpg"} alt="" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="400"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/thelightear/2.jpg"} alt="" />
      </div>
      <div
        data-sal="slide-up"
        data-sal-delay="600"
        data-sal-easing="ease"
        data-sal-duration="900"
        style={{ minHeight: "50vh" }}
      >
        <img src={"/images/thelightear/4.jpg"} alt="" />
      </div>
    </div>
  </Layout>
)

export default TlePage
